import '../styles/style.scss';

// import 'js/vendor/jquery.ba-throttle-debounce.min.js';
import 'jquery.scrollbar/jquery.scrollbar.min';
import 'js-cookie/src/js.cookie';

import '../img/payment-icons-v13/amazon-payments.png';
import '../img/payment-icons-v13/american-express.png';
import '../img/payment-icons-v13/banktransfer.png';
import '../img/payment-icons-v13/barzahlen.png';
import '../img/payment-icons-v13/barzahlen-alternative.png';
import '../img/payment-icons-v13/billpay.png';
import '../img/payment-icons-v13/billsafe.png';
import '../img/payment-icons-v13/bitcoin.png';
import '../img/payment-icons-v13/bitcoin-alternative.png';
import '../img/payment-icons-v13/bitcoin-alternative2.png';
import '../img/payment-icons-v13/cartasi.png';
import '../img/payment-icons-v13/carte-blanche.png';
import '../img/payment-icons-v13/carte-bleue.png';
import '../img/payment-icons-v13/carte-bleue-old.png';
import '../img/payment-icons-v13/cash-on-pickup.png';
import '../img/payment-icons-v13/cirrus.png';
import '../img/payment-icons-v13/clickandbuy.png';
import '../img/payment-icons-v13/dhl-cod.png';
import '../img/payment-icons-v13/diners-club.png';
import '../img/payment-icons-v13/direct-debit.png';
import '../img/payment-icons-v13/discover.png';
import '../img/payment-icons-v13/dpd-cod.png';
import '../img/payment-icons-v13/dpd-cod-alternate.png';
import '../img/payment-icons-v13/ec.png';
import '../img/payment-icons-v13/eps-netpay.png';
import '../img/payment-icons-v13/girocard.png';
import '../img/payment-icons-v13/giropay.png';
import '../img/payment-icons-v13/gls-cod.png';
import '../img/payment-icons-v13/gls-cod-alternate.png';
import '../img/payment-icons-v13/hermes-cod.png';
import '../img/payment-icons-v13/ideal.png';
import '../img/payment-icons-v13/invoice.png';
import '../img/payment-icons-v13/invoice-alternate.png';
import '../img/payment-icons-v13/invoice-at-female.png';
import '../img/payment-icons-v13/invoice-at-female-banner.png';
import '../img/payment-icons-v13/invoice-at-male.png';
import '../img/payment-icons-v13/invoice-at-male-banner.png';
import '../img/payment-icons-v13/invoice-ch-female.png';
import '../img/payment-icons-v13/invoice-ch-female-banner.png';
import '../img/payment-icons-v13/invoice-ch-male.png';
import '../img/payment-icons-v13/invoice-ch-male-banner.png';
import '../img/payment-icons-v13/invoice-de-female-banner-bb.png';
import '../img/payment-icons-v13/invoice-de-female-banner-be.png';
import '../img/payment-icons-v13/invoice-de-female-banner-bw.png';
import '../img/payment-icons-v13/invoice-de-female-banner-by.png';
import '../img/payment-icons-v13/invoice-de-female-banner-hb.png';
import '../img/payment-icons-v13/invoice-de-female-banner-he.png';
import '../img/payment-icons-v13/invoice-de-female-banner-hh.png';
import '../img/payment-icons-v13/invoice-de-female-banner-mv.png';
import '../img/payment-icons-v13/invoice-de-female-banner-ni.png';
import '../img/payment-icons-v13/invoice-de-female-banner-nw.png';
import '../img/payment-icons-v13/invoice-de-female-banner-rp.png';
import '../img/payment-icons-v13/invoice-de-female-banner-sh.png';
import '../img/payment-icons-v13/invoice-de-female-banner-sl.png';
import '../img/payment-icons-v13/invoice-de-female-banner-sn.png';
import '../img/payment-icons-v13/invoice-de-female-banner-st.png';
import '../img/payment-icons-v13/invoice-de-female-banner-th.png';
import '../img/payment-icons-v13/invoice-de-female-bb.png';
import '../img/payment-icons-v13/invoice-de-female-be.png';
import '../img/payment-icons-v13/invoice-de-female-bw.png';
import '../img/payment-icons-v13/invoice-de-female-by.png';
import '../img/payment-icons-v13/invoice-de-female-hb.png';
import '../img/payment-icons-v13/invoice-de-female-he.png';
import '../img/payment-icons-v13/invoice-de-female-hh.png';
import '../img/payment-icons-v13/invoice-de-female-mv.png';
import '../img/payment-icons-v13/invoice-de-female-ni.png';
import '../img/payment-icons-v13/invoice-de-female-nw.png';
import '../img/payment-icons-v13/invoice-de-female-rp.png';
import '../img/payment-icons-v13/invoice-de-female-sh.png';
import '../img/payment-icons-v13/invoice-de-female-sl.png';
import '../img/payment-icons-v13/invoice-de-female-sn.png';
import '../img/payment-icons-v13/invoice-de-female-st.png';
import '../img/payment-icons-v13/invoice-de-female-th.png';
import '../img/payment-icons-v13/invoice-de-female-wm-banner-bb.png';
import '../img/payment-icons-v13/invoice-de-female-wm-banner-be.png';
import '../img/payment-icons-v13/invoice-de-female-wm-banner-bw.png';
import '../img/payment-icons-v13/invoice-de-female-wm-banner-by.png';
import '../img/payment-icons-v13/invoice-de-female-wm-banner-hb.png';
import '../img/payment-icons-v13/invoice-de-female-wm-banner-he.png';
import '../img/payment-icons-v13/invoice-de-female-wm-banner-hh.png';
import '../img/payment-icons-v13/invoice-de-female-wm-banner-mv.png';
import '../img/payment-icons-v13/invoice-de-female-wm-banner-ni.png';
import '../img/payment-icons-v13/invoice-de-female-wm-banner-nw.png';
import '../img/payment-icons-v13/invoice-de-female-wm-banner-rp.png';
import '../img/payment-icons-v13/invoice-de-female-wm-banner-sh.png';
import '../img/payment-icons-v13/invoice-de-female-wm-banner-sl.png';
import '../img/payment-icons-v13/invoice-de-female-wm-banner-sn.png';
import '../img/payment-icons-v13/invoice-de-female-wm-banner-st.png';
import '../img/payment-icons-v13/invoice-de-female-wm-banner-th.png';
import '../img/payment-icons-v13/invoice-de-female-wm-bb.png';
import '../img/payment-icons-v13/invoice-de-female-wm-be.png';
import '../img/payment-icons-v13/invoice-de-female-wm-bw.png';
import '../img/payment-icons-v13/invoice-de-female-wm-by.png';
import '../img/payment-icons-v13/invoice-de-female-wm-hb.png';
import '../img/payment-icons-v13/invoice-de-female-wm-he.png';
import '../img/payment-icons-v13/invoice-de-female-wm-hh.png';
import '../img/payment-icons-v13/invoice-de-female-wm-mv.png';
import '../img/payment-icons-v13/invoice-de-female-wm-ni.png';
import '../img/payment-icons-v13/invoice-de-female-wm-nw.png';
import '../img/payment-icons-v13/invoice-de-female-wm-rp.png';
import '../img/payment-icons-v13/invoice-de-female-wm-sh.png';
import '../img/payment-icons-v13/invoice-de-female-wm-sl.png';
import '../img/payment-icons-v13/invoice-de-female-wm-sn.png';
import '../img/payment-icons-v13/invoice-de-female-wm-st.png';
import '../img/payment-icons-v13/invoice-de-female-wm-th.png';
import '../img/payment-icons-v13/invoice-de-male-banner-bb.png';
import '../img/payment-icons-v13/invoice-de-male-banner-be.png';
import '../img/payment-icons-v13/invoice-de-male-banner-bw.png';
import '../img/payment-icons-v13/invoice-de-male-banner-by.png';
import '../img/payment-icons-v13/invoice-de-male-banner-hb.png';
import '../img/payment-icons-v13/invoice-de-male-banner-he.png';
import '../img/payment-icons-v13/invoice-de-male-banner-hh.png';
import '../img/payment-icons-v13/invoice-de-male-banner-mv.png';
import '../img/payment-icons-v13/invoice-de-male-banner-ni.png';
import '../img/payment-icons-v13/invoice-de-male-banner-nw.png';
import '../img/payment-icons-v13/invoice-de-male-banner-rp.png';
import '../img/payment-icons-v13/invoice-de-male-banner-sh.png';
import '../img/payment-icons-v13/invoice-de-male-banner-sl.png';
import '../img/payment-icons-v13/invoice-de-male-banner-sn.png';
import '../img/payment-icons-v13/invoice-de-male-banner-st.png';
import '../img/payment-icons-v13/invoice-de-male-banner-th.png';
import '../img/payment-icons-v13/invoice-de-male-bb.png';
import '../img/payment-icons-v13/invoice-de-male-be.png';
import '../img/payment-icons-v13/invoice-de-male-bw.png';
import '../img/payment-icons-v13/invoice-de-male-by.png';
import '../img/payment-icons-v13/invoice-de-male-hb.png';
import '../img/payment-icons-v13/invoice-de-male-he.png';
import '../img/payment-icons-v13/invoice-de-male-hh.png';
import '../img/payment-icons-v13/invoice-de-male-mv.png';
import '../img/payment-icons-v13/invoice-de-male-ni.png';
import '../img/payment-icons-v13/invoice-de-male-nw.png';
import '../img/payment-icons-v13/invoice-de-male-rp.png';
import '../img/payment-icons-v13/invoice-de-male-sh.png';
import '../img/payment-icons-v13/invoice-de-male-sl.png';
import '../img/payment-icons-v13/invoice-de-male-sn.png';
import '../img/payment-icons-v13/invoice-de-male-st.png';
import '../img/payment-icons-v13/invoice-de-male-th.png';
import '../img/payment-icons-v13/invoice-de-male-wm-banner-bb.png';
import '../img/payment-icons-v13/invoice-de-male-wm-banner-be.png';
import '../img/payment-icons-v13/invoice-de-male-wm-banner-bw.png';
import '../img/payment-icons-v13/invoice-de-male-wm-banner-by.png';
import '../img/payment-icons-v13/invoice-de-male-wm-banner-hb.png';
import '../img/payment-icons-v13/invoice-de-male-wm-banner-he.png';
import '../img/payment-icons-v13/invoice-de-male-wm-banner-hh.png';
import '../img/payment-icons-v13/invoice-de-male-wm-banner-mv.png';
import '../img/payment-icons-v13/invoice-de-male-wm-banner-ni.png';
import '../img/payment-icons-v13/invoice-de-male-wm-banner-nw.png';
import '../img/payment-icons-v13/invoice-de-male-wm-banner-rp.png';
import '../img/payment-icons-v13/invoice-de-male-wm-banner-sh.png';
import '../img/payment-icons-v13/invoice-de-male-wm-banner-sl.png';
import '../img/payment-icons-v13/invoice-de-male-wm-banner-sn.png';
import '../img/payment-icons-v13/invoice-de-male-wm-banner-st.png';
import '../img/payment-icons-v13/invoice-de-male-wm-banner-th.png';
import '../img/payment-icons-v13/invoice-de-male-wm-bb.png';
import '../img/payment-icons-v13/invoice-de-male-wm-be.png';
import '../img/payment-icons-v13/invoice-de-male-wm-bw.png';
import '../img/payment-icons-v13/invoice-de-male-wm-by.png';
import '../img/payment-icons-v13/invoice-de-male-wm-hb.png';
import '../img/payment-icons-v13/invoice-de-male-wm-he.png';
import '../img/payment-icons-v13/invoice-de-male-wm-hh.png';
import '../img/payment-icons-v13/invoice-de-male-wm-mv.png';
import '../img/payment-icons-v13/invoice-de-male-wm-ni.png';
import '../img/payment-icons-v13/invoice-de-male-wm-nw.png';
import '../img/payment-icons-v13/invoice-de-male-wm-rp.png';
import '../img/payment-icons-v13/invoice-de-male-wm-sh.png';
import '../img/payment-icons-v13/invoice-de-male-wm-sl.png';
import '../img/payment-icons-v13/invoice-de-male-wm-sn.png';
import '../img/payment-icons-v13/invoice-de-male-wm-st.png';
import '../img/payment-icons-v13/invoice-de-male-wm-th.png';
import '../img/payment-icons-v13/jcb.png';
import '../img/payment-icons-v13/klarna.png';
import '../img/payment-icons-v13/klarna-alt.png';
import '../img/payment-icons-v13/klarna-old.png';
import '../img/payment-icons-v13/klarna-ratenkauf.png';
import '../img/payment-icons-v13/klarna-rechnung.png';
import '../img/payment-icons-v13/maestro.png';
import '../img/payment-icons-v13/manor-myone-card.png';
import '../img/payment-icons-v13/mastercard.png';
import '../img/payment-icons-v13/mastercard-alternate.png';
import '../img/payment-icons-v13/masterpass.png';
import '../img/payment-icons-v13/masterpass-alternate.png';
import '../img/payment-icons-v13/micropayment.png';
import '../img/payment-icons-v13/micropayment-alternative.png';
import '../img/payment-icons-v13/moneybookers.png';
import '../img/payment-icons-v13/moneyorder.png';
import '../img/payment-icons-v13/payment-in-advance.png';
import '../img/payment-icons-v13/payment-in-advance-alternate.png';
import '../img/payment-icons-v13/payment-in-advance-skonto.png';
import '../img/payment-icons-v13/paymorrow.png';
import '../img/payment-icons-v13/paypal.png';
import '../img/payment-icons-v13/paypal-alternative.png';
import '../img/payment-icons-v13/paypal-alternative2.png';
import '../img/payment-icons-v13/paypal-old.png';
import '../img/payment-icons-v13/paysafecard.png';
import '../img/payment-icons-v13/paysafecard-alternative.png';
import '../img/payment-icons-v13/post-austria-cod.png';
import '../img/payment-icons-v13/postepay.png';
import '../img/payment-icons-v13/postfinance.png';
import '../img/payment-icons-v13/postfinance-alternate.png';
import '../img/payment-icons-v13/postfinance-alternate2.png';
import '../img/payment-icons-v13/postpay.png';
import '../img/payment-icons-v13/sage-pay.png';
import '../img/payment-icons-v13/sepa.png';
import '../img/payment-icons-v13/servired.png';
import '../img/payment-icons-v13/servired-alternate.png';
import '../img/payment-icons-v13/skrill.png';
import '../img/payment-icons-v13/sofortueberweisung.png';
import '../img/payment-icons-v13/sofortueberweisung-old.png';
import '../img/payment-icons-v13/solo.png';
import '../img/payment-icons-v13/switch.png';
import '../img/payment-icons-v13/twint.png';
import '../img/payment-icons-v13/ups-cod.png';
import '../img/payment-icons-v13/ups-cod-alternate.png';
import '../img/payment-icons-v13/v-pay.png';
import '../img/payment-icons-v13/visa.png';
import '../img/payment-icons-v13/visa-electron.png';
import '../img/payment-icons-v13/vr-pay.png';
import '../img/payment-icons-v13/western-union.png';
import '../img/payment-icons-v13/wirecard.png';
import '../img/payment-icons-v13/yapital.png';
import '../img/payment-icons-v13/yapital-alternative.png';
import '../img/badge_angebot.png';
import '../img/badge_angebot.svg';
import '../img/badge_empfehlung.png';
import '../img/badge_neu.png';
import '../img/badge_neu.svg';
import '../img/badge_paket.png';
import '../img/badge_paket.svg';
import '../img/badge_reine-rindswurst.png';
import '../img/load-animation.gif';
import '../img/Logo_Unser-Rind_Website-Top.svg';
import '../img/newsletter-1.svg';
import '../img/newsletter-2.svg';
import '../img/newsletter-3.svg';
import '../img/newsletter-fuenf-euro.svg';
import '../img/rind_bg_1920.jpg';
import '../img/select-arrow-purple.png';

var debug = false,
    breakpoints = {
        xs: 320,
        s: 768,
        m: 1000,
        l: 1023
    };

jQuery(document).ready(function () {
    bindNavigationEvents();

    /**
     * bind resize event
     */
    jQuery(window).on('resize', onResize);
    jQuery(window).trigger('resize');

    /**
     * Woocommerce:
     * scroll to top on pagination button click
     */
    jQuery('.site-content').on('click', '.woocommerce-pagination a.page-numbers', function (event) {
        event.preventDefault();
        var pos = jQuery('#woof_results_by_ajax').position();
        jQuery(window).animate({scrollTop: pos.top - 80}, 1800);
    });

    setTimeout(function () {
        setSlickSliderHeight();
    }, 1000);

    /**
     * init overlays (newsletter and cookie notice)
     */
    initOverlays();

    /**
     * init cookie notice
     */
    initCookieNotice();

    /**
     *
     */
    initNewsletterOverlay();
});

function initOverlays() {
    var $overlays = jQuery('.overlay'),
        animationDuration = 200;

    $overlays.on('click', '.overlay-close', function (event) {
        event.preventDefault();
        jQuery('body').removeClass('no-scroll');
        jQuery(this).parents('.overlay').fadeOut(animationDuration);
    });

    $overlays.on('click', function (event) {
        if (event.target === jQuery(this)[0]) {
            event.preventDefault();
            event.stopPropagation();
            jQuery('body').removeClass('no-scroll');
            jQuery(this).fadeOut(animationDuration);
        }
    });
}

function initNewsletterOverlay() {
    var $newsletterOverlay = jQuery('#newsletter-overlay'),
        animationDuration = 300;

    if (Cookies.get('page-views') === undefined) {
        Cookies.set('page-views', 1);
    } else {
        var pageViews = parseInt(Cookies.get('page-views')) + 1;
        Cookies.set('page-views', pageViews);
    }

    // show newsletter overlay on second page view
    if (pageViews === 2) {
        jQuery('body').addClass('no-scroll');
        $newsletterOverlay.fadeIn(animationDuration);
    }
}

function initCookieNotice() {
    /**
     * custom scrollbars
     */
    jQuery('.scrollbar-inner').scrollbar();

    var $cookieNotice = jQuery('#cookie-notice'),
        $cookieOverlay = jQuery('#cookie-overlay'),
        $cookieOverlayMenu = $cookieOverlay.find('.cookie-overlay-menu'),
        $cookieOverlayContents = $cookieOverlay.find('.cookie-overlay-content'),
        $btnOpenOverlay = $cookieNotice.find('#cookie-overlay-open'),
        $btnAccept = $cookieNotice.find('#cookie-notice-accept'),
        animationDuration = 150;

    if (Cookies.get('accept-cookie') !== '1') {
        $cookieNotice.slideDown(animationDuration);
    }

    $btnOpenOverlay.on('click', function (event) {
        event.preventDefault();
        jQuery('body').addClass('no-scroll');
        $cookieOverlay.fadeIn(300);
    });

    $btnAccept.on('click', function (event) {
        event.preventDefault();
        Cookies.set('accept-cookie', '1');
        $cookieNotice.slideUp(animationDuration);
    });

    /**
     * open first cookie overlay menu item
     */
    $cookieOverlayMenu.on('click', 'li', function (event) {
        event.preventDefault();
        var $el = jQuery(event.target),
            contentHref = $el.data('content-href');

        $cookieOverlayMenu.find('li').removeClass('active');
        $el.addClass('active');

        $cookieOverlayContents.hide();
        $cookieOverlayContents.each(function (key, value) {
            if (jQuery(value).data('content-ref') === contentHref) {
                jQuery(value).show();
            }
        });
    });
    $cookieOverlayMenu.find('li').first().trigger('click');
}

function onResize() {
    if (debug) {
        console.log("onResize triggered");
        setSlickSliderHeight();
    }
}

function bindNavigationEvents() {
    /**
     * desktop submenu handling
     */
    var $submenuToggle = jQuery('.menu-item-has-children'),
        viewportDimensions = {
            width: jQuery(window).outerWidth(),
            height: jQuery(window).outerHeight()
        };

    $submenuToggle.hover(function (e) {
        if (viewportDimensions.width <= breakpoints.l) {
            e.preventDefault();
            e.stopPropagation();
        }
    });

    $submenuToggle.click(function (e) {
        if (jQuery(e.target).parent().hasClass('menu-item-has-children')) {
            e.preventDefault();

            var $submenu = jQuery(this).find('.sub-menu');

            if (!$submenu.is(':visible')) {
                $submenu.show();
            } else {
                $submenu.hide();
            }
        }
    });

    jQuery('input#place_order').on('click', function (event) {
        if (!jQuery('input#legal').attr('checked')) {
            event.stopPropagation();
            event.preventDefault();
            jQuery('.wc-terms-and-conditions').addClass('legal-error');
            var pos = jQuery('.wc-terms-and-conditions').position();
            jQuery(window).animate({scrollTop: pos.top - 80}, 1000);
        } else {
            jQuery('.wc-terms-and-conditions').removeClass('legal-error');
        }
    });
}

function setSlickSliderHeight() {
    var $slickInitialized = jQuery('.slick-initialized');
    var $images = $slickInitialized.find('img');
    var height = jQuery($images[1]).height();
    if (height) {
        jQuery('.slick-list').css('height', height + 160);
    }
}
